import consumer from "./consumer"

consumer.subscriptions.create({channel: "StatusChannel"},{
  received(bots) {
    bots.forEach(function(bot) {
      $(`#status_${bot['id']}`).text(bot['status']);
      const toggle_icon = $(`#toggle_icon_${bot['id']}`);
      const available = bot['status'] === 'available';
      toggle_icon.removeClass(`fa-${available? "play" : "pause"}-circle`);
      toggle_icon.addClass(`fa-${available? "pause" : "play"}-circle`);
    });
  }

})